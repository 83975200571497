@import url(./styles/animations.css);
@import url(./styles/fonts.css);

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  font-family: Inter, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.page-layout {
  animation: show 1000ms linear 50ms;
}

.grecaptcha-badge {
  visibility: hidden;
}

main {
  width: 100%;
}

@media print {
  * {
    overflow: visible !important;
    width: auto !important;
    height: auto !important;
    max-height: auto !important;
    page-break-before: always !important;
  }

  header nav,
  footer {
    display: none;
  }

  @page {
    margin: 1.5cm 1.5cm 1.5cm 2cm;
    marks: none;
    orphans: 4;
    widows: 2;
    size: portrait;
  }
}

main {
  height: 100%;
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.container {
  width: 100%;
  max-width: 1104px;
}

@media (min-width: 660px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 659.9px) {
  .hidden-mobile {
    display: none !important;
  }
}

button,
input,
textarea {
  font-family: inherit;
}

a {
  text-underline-position: under;
}

@media (hover: none) {
  * {
    cursor: default;
  }
}

.scrollbar > div:nth-child(3) {
  width: 4px !important;
  right: 0 !important;
  border-radius: 4px;
}

.scrollbar > div:nth-child(3) > div {
  border-radius: 4px;
}

.show-content {
  overflow: hidden;
  height: 100%;
  opacity: 1;
  transition: all 200ms ease;
}

.hide-content {
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: all 200ms ease;
}

.infinite-scroll-component__outerdiv {
  width: 100%;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
