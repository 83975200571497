@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

@keyframes hide-opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes menuAppearance {
  0% {
    width: 20px;
    height: 20px;
  }
  100% {
    width: 250vh;
    height: 250vh;
  }
}

@keyframes menuAppearanceLandscape {
  0% {
    width: 20px;
    height: 20px;
  }
  100% {
    width: 250vw;
    height: 250vw;
  }
}

@keyframes ellipsis {
  from {
    width: 0;
  }
  to {
    width: 1em;
  }
}

@keyframes change-card-content {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.rotation {
  animation: rotation 500ms ease-in 0s infinite;
}

.hide {
  animation: hide 200ms ease-in 0s;
  opacity: 0;
  height: 0;
}

.show {
  animation: show 400ms ease-in 0s;
  opacity: 1;
  height: auto;
}

.change-card-content {
  animation: change-card-content 600ms linear 0s;
}

.hide-filter-header-content {
  animation: hide-opacity 200ms ease 0s;
  opacity: 0;
}

.show-filter-header-content {
  animation: show 200ms ease 0s;
  opacity: 1;
}

@keyframes hide-filters-body-to-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-40px);
    opacity: 0;
  }
}

@keyframes hide-filters-body-to-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(40px);
    opacity: 0;
  }
}


.hide-filters-body-to-left {
  animation: hide-filters-body-to-left 200ms ease;
  opacity: 0;
  transition: translateX(-40px);
}

.hide-filters-body-to-right {
  animation: hide-filters-body-to-right 200ms ease;
  opacity: 0;
  transition: translateX(-40px);
}

@keyframes show-filters-body-from-left {
  0% {
    transform: translateX(-40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes show-filters-body-from-right {
  0% {
    transform: translateX(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}


.show-filters-body-from-left {
  animation: show-filters-body-from-left 200ms ease;
  opacity: 1;
  transform: translateX(0);
}

.show-filters-body-from-right {
  animation: show-filters-body-from-right 200ms ease;
  opacity: 1;
  transform: translateX(0);
}

.filters-hidden {
  display: none !important;
}

.filters-item {
  position: absolute;
  top: 0;
  left: 0;
}